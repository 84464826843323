<template>
    <GlobalHome v-if="isInternational" />
    <UnGlobalHome v-else />
  </template>

<script>
import GlobalHome from '../globalHome';
import UnGlobalHome from '../unGlobalHome';
import isInternational from '@/utils/isInternational'

export default {
  name: "Home",
  components: {
    GlobalHome,
    UnGlobalHome,
  },
  computed: {
    isInternational() {
      return isInternational();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
